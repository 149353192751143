import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { InnerSmall } from '../../../common/inner';
import media from '../../../common/MediaQueries';
import {
  slideInStartAnimStyles,
  slideInEndAnimStyles,
  opacityTransformTransition,
} from '../../../common/mixins';
import { HeadingS } from '../../styles/Typography';
import { Links } from '../../atoms/Links/styles';
import { FooterStyleProps } from './types';

const socialWidthVAlue = '9rem';

export const FooterContainer = styled.div<FooterStyleProps>`
  width: 100%;
  max-width: 69.875rem;
  margin: 0 auto;

  ${({ withBorder }) =>
    withBorder &&
    css`
      @media ${media.phone} {
        border: 0.25rem solid ${({ theme }) => theme.colors.primary.main};
        border-radius: 0.5rem;
        padding: 3rem 0;
      }
    `};
`;

export const FooterInner = styled(InnerSmall)``;

export const FooterLogoWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const FooterLogo = styled(Link)`
  width: 11.5rem;
  height: 3.125rem;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${media.phone} {
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    justify-content: space-between;
  }

  @media ${media.tablet} {
    padding-right: 1rem;
    justify-content: center;
  }
`;

export const FooterInfo = styled.div`
  ${Links} + ${Links} {
    margin-top: 0.5rem;
  }
  order: 0;
  @media ${media.phone} {
    width: calc(46% - 4rem - ${socialWidthVAlue});
    margin: 0 1rem;
    ${Links} + ${Links} {
      margin-top: 1rem;
    }
  }
`;

export const FooterInfoItem = styled.div``;

export const FooterSocials = styled.div`
  order: 1;
  margin: 1.5rem 0 2rem;
  @media ${media.phone} {
    order: 3;
    margin: 1.25rem 1rem 0 0;
    width: ${socialWidthVAlue};
  }
`;

export const FooterSocialsLabel = styled(HeadingS)`
  margin-bottom: 1.5rem;
  @media ${media.maxPhone} {
    display: none;
  }
`;

export const FooterMenu = styled.div`
  order: 2;

  @media ${media.maxPhone} {
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: '';
      background-color: ${({ theme }) => theme.colors.additional.brandDark};
      height: 1px;
      width: calc(100% + 3rem);
      left: -1.5rem;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }

  @media ${media.phone} {
    order: 1;
    width: calc(42% - 2rem);
    margin: 0 1rem;
  }
`;

export const FooterMenuLabel = styled(HeadingS)`
  @media ${media.maxPhone} {
    display: none;
  }
`;

export const FooterMenuList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 1.5rem);
  margin-left: -0.75rem;
  padding: 0.75rem 0;

  @media ${media.tablet} {
    width: calc(100% + 1.5rem);
    margin-left: -0.75rem;
    max-width: 22.5rem;
  }
`;

export const FooterMenuListItem = styled.li`
  padding: 0.75rem;
  width: 33.3%;
`;

export const FooterCopyright = styled.div`
  order: 4;
  margin-top: 1rem;
  @media ${media.maxPhone} {
    p {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
  @media ${media.phone} {
    width: calc(42% - 2rem);
    margin: 1.875rem 1rem 0;
  }
`;

export const FooterLocation = styled.div`
  order: 3;
  margin-top: 2rem;
  white-space: pre;

  @media ${media.phone} {
    width: calc(46% - 4rem - ${socialWidthVAlue});
    margin: 1.875rem 1rem 0;
  }
`;

export const EmptyDiv = styled(FooterSocials)`
  order: 6;
`;

export const Footer = styled.footer<{ isVisible?: boolean }>`
  padding: 4rem 0 3.25rem;

  @media ${media.phone} {
    padding: 4rem 0.5rem 3.25rem;
  }

  ${opacityTransformTransition()}
  ${slideInStartAnimStyles}

  ${({ isVisible }) =>
    isVisible &&
    css`
      ${slideInEndAnimStyles}
    `}
`;
