import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { transition } from '../../../common/mixins';
import { LinkStylesProps, LinkVariantProps } from './types';
import media from '../../../common/MediaQueries';

export const linkBtnStyles = css`
  ${transition()};
  background-color: ${({ theme }) => theme.colors.primary.main};
  padding: 0.875rem 1.5rem;
  height: 3.25rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  outline: 0;
  svg {
    color: ${({ theme }) => theme.colors.neutral.white};
  }
`;

const linkStyles = css<LinkStylesProps>`
  ${transition()};
  display: inline-flex;
  align-items: center;
  svg,
  p {
    ${transition()};
  }

  &:hover {
    svg,
    p {
      color: ${({ theme }) => theme.colors.primary.hover};
    }
    svg {
      transform: translateX(0.125rem);
    }
  }

  ${({ withoutHover }) =>
    withoutHover &&
    css`
      &:hover {
        svg,
        p {
          color: inherit;
        }
      }
    `}

  ${({ isCMS, theme }) =>
    isCMS &&
    css`
      text-decoration: underline;
      text-decoration-color: ${theme.colors.primary.main};
    `}

    ${({ isTransparent }) =>
    isTransparent &&
    css`
      ${linkBtnStyles}
      border: 1px solid ${({ theme }) => theme.colors.primary.main};
      svg,
      p {
        color: ${({ theme }) => theme.colors.primary.main};
        text-transform: none;
      }

      background: transparent;
      &:hover {
        background-color: ${({ theme }) => theme.colors.primary.main};
        svg,
        p {
          color: ${({ theme }) => theme.colors.neutral.white};
        }
      }
    `}

  ${({ isBtnAccented }) =>
    isBtnAccented &&
    css`
      ${linkBtnStyles}
      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.white};
        svg,
        p {
          color: ${({ theme }) => theme.colors.primary.hover};
        }
      }
    `}

  ${({ isBtnBordered }) =>
    isBtnBordered &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.main};
      height: 2rem;
      padding: 0.35rem;
      border: 0.125rem solid ${({ theme }) => theme.colors.additional.lightGreen};
      border-radius: 0.5rem;
      text-align: center;
      white-space: nowrap;
      p {
        color: ${({ theme }) => theme.colors.additional.lightGreen};
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors.additional.lightGreen};
        p {
          color: ${({ theme }) => theme.colors.primary.main};
        }
      }
      @media ${media.phone} {
        height: 2.25rem;
        padding: 0.35rem 1.5rem;
      }
    `}

    ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
      border: 0.125rem solid ${backgroundColor};
    `}

    ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`;

const Links = styled.div``;

const LinksStories = styled.div`
  padding: 2rem;
`;

const LinkInternal = styled(Link)<LinkStylesProps>`
  ${linkStyles}
`;

const LinkExternal = styled.a<LinkStylesProps>`
  ${linkStyles}
`;

const LinkPlain = styled.div<LinkStylesProps>`
  ${linkStyles}
  &:hover {
    svg {
      transform: translateX(0);
    }
  }
`;

const LinkIcon = styled.div<LinkVariantProps>`
  display: flex;
  &:first-child {
    margin-right: 0.625rem;
  }
  &:not(:first-child) {
    margin-left: 1.125rem;
  }
  ${({ color }) =>
    color &&
    css`
      svg {
        height: 0.8125rem;
        width: 1em;
        color: ${color};
      }
    `}

  ${({ svgSize }) =>
    svgSize &&
    css`
      svg {
        height: ${svgSize};
      }
    `}
`;

const LinkBtn = styled.button`
  display: inline-flex;
  ${linkBtnStyles}
  ${LinkIcon} {
    transform: rotate(90deg);
    margin-left: 0.5rem;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.white};
    svg,
    p {
      color: ${({ theme }) => theme.colors.primary.hover};
    }
  }
`;

export { Links, LinkInternal, LinkExternal, LinksStories, LinkIcon, LinkPlain, LinkBtn };
