import React from 'react';
import { TextS, TextXS, TextM, TextL, HeadingS } from '../../styles/Typography';
import { Typo } from '../../../enums/typo';

export const renderTypoSwitch = (
  label: string | React.ReactNode,
  param?: string,
  colorVal?: string,
  fontWeight?: string | string[],
  childrenWrapperElement: keyof React.ReactHTML = 'p'
) => {
  switch (param) {
    case `${Typo.HeadingS}`:
      return (
        <HeadingS as={childrenWrapperElement} fontWeight={fontWeight || 'bold'} color={colorVal}>
          {label}
        </HeadingS>
      );
    case `${Typo.TextL}`:
      return (
        <TextL as={childrenWrapperElement} fontWeight={fontWeight || 'bold'} color={colorVal}>
          {label}
        </TextL>
      );
    case `${Typo.TextXS}`:
      return (
        <TextXS as={childrenWrapperElement} fontWeight={fontWeight || 'bold'} color={colorVal}>
          {label}
        </TextXS>
      );
    case `${Typo.TextS}`:
      return (
        <TextS as={childrenWrapperElement} fontWeight={fontWeight || 'bold'} color={colorVal}>
          {label}
        </TextS>
      );
    default:
      return (
        <TextM as={childrenWrapperElement} fontWeight={fontWeight || 'bold'} color={colorVal}>
          {label}
        </TextM>
      );
  }
};
