import styled from 'styled-components';
import media from '../../../common/MediaQueries';

export const BackgroundPatternWrapper = styled.div`
  display: none;

  svg {
    fill: none;
  }

  @media ${media.tablet} {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 195.625rem;
    height: 215.125rem;
    z-index: -1;
    max-height: 100%;
    max-width: 100vw;
  }
`;
