import styled from 'styled-components';

import { theme } from '../../../style/theme';
import StyledDynamicTypo from '../../../common/dynamicTypo';
import { TypoProps } from './types';

const { textXS, textS, textM, textL, headingS, headingM, headingL, headingL2, headingXL } =
  theme.textStyles;

const createTypoComponent = (props: TypoProps) => {
  const TypoComponent = styled(StyledDynamicTypo)``;

  TypoComponent.defaultProps = {
    ...props,
  };

  return TypoComponent;
};

export const TextXS = createTypoComponent(textXS);
export const TextS = createTypoComponent(textS);
export const TextM = createTypoComponent(textM);
export const TextL = createTypoComponent(textL);
export const HeadingS = createTypoComponent(headingS);
export const HeadingM = createTypoComponent(headingM);
export const HeadingL = createTypoComponent(headingL);
export const HeadingL2 = createTypoComponent(headingL2);
export const HeadingXL = createTypoComponent(headingXL);
