import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useMemo, useState } from 'react';
import { getImage } from '../utils/image';
import { getHref, isLinkExternal } from '../utils/link';
import { getFooterMenuItems, getMenuItems } from '../utils/navigation';
import { UseGlobalSettingsReturnType } from './types';

export const useConsoleMessage = (message: string | undefined | null) => {
  useEffect(() => {
    if (message && message.length > 0) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }, [message]);
};

export const useGlobalSettings = (): UseGlobalSettingsReturnType => {
  const {
    sanityCallToActionBar,
    sanityMainNavigation,
    sanityFooter,
    sanityPrivacyPolicy,
    sanityConsoleMessage,
  } = useStaticQuery<GatsbyTypes.GlobalSettingsQuery>(graphql`
    query GlobalSettings {
      sanityCallToActionBar {
        text
        mobileText
        button {
          buttonText
          link {
            ...LinkFragment
          }
        }
      }
      sanityMainNavigation {
        email
        phoneNumber
        logo {
          ...ImageFragment
        }
        socialLinks {
          _key
          icon {
            ...SVGImageFragment
          }
          link {
            externalUrl
          }
        }
        contactUsButton {
          ...LinkFragment
        }
        menu {
          items {
            ... on SanityMenuLink {
              _key
              _type
              label
              link {
                ...LinkFragment
              }
            }
            ... on SanityMenuSubItem {
              _key
              _type
              label
              link {
                ...LinkFragment
              }
              menuLinks {
                _key
                label
                link {
                  ...LinkFragment
                }
              }
            }
          }
        }
      }
      sanityFooter {
        email
        phoneNumber
        copyright
        location
        logo {
          ...ImageFragment
        }
        socialLinks {
          _key
          icon {
            ...SVGImageFragment
          }
          link {
            externalUrl
          }
        }
        footerMenu {
          name
          items {
            _key
            label
            link {
              ...LinkFragment
            }
          }
        }
      }
      sanityPrivacyPolicy {
        page {
          slug {
            current
          }
        }
      }
      sanityConsoleMessage {
        message
      }
    }

    fragment ImageFragment on SanityImageWithAlt {
      alt
      asset {
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    fragment SVGImageFragment on SanitySvgImage {
      alt
      asset {
        gatsbyImageData(placeholder: NONE)
      }
    }

    fragment LinkFragment on SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost {
      ... on SanityExternalUrl {
        _type
        externalUrl
      }
      ... on SanityPage {
        _type
        slug {
          _key
          _type
          current
        }
      }
      ... on SanityPost {
        _type
        slug {
          _key
          _type
          current
        }
      }
      ... on SanityCaseStudy {
        _type
        slug {
          _key
          _type
          current
        }
      }
      ... on SanityMedia {
        _type
        asset {
          url
        }
      }
    }
  `);

  useConsoleMessage(sanityConsoleMessage?.message);

  return useMemo(() => {
    const { button, text = '', mobileText = '' } = sanityCallToActionBar ?? {};
    const [url] = button?.link ?? [];

    const {
      menu,
      email = '',
      phoneNumber = '',
      logo,
      socialLinks = [],
      contactUsButton,
    } = sanityMainNavigation ?? {};
    const { items: menuItems = [] } = menu ?? {};
    const logoImage = getImage(logo as GatsbyTypes.SanityImageWithAlt);

    const {
      logo: footerLogo,
      phoneNumber: footerPhoneNumber = '',
      email: footerEmail = '',
      copyright = '',
      location = '',
      socialLinks: footerSocialLinks = [],
      footerMenu,
    } = sanityFooter ?? {};
    const footerLogoImage = getImage(footerLogo as GatsbyTypes.SanityImageWithAlt);
    const { items = [], name = '' } = footerMenu ?? {};

    const { page } = sanityPrivacyPolicy ?? {};
    const { slug } = page ?? {};
    const { current = '' } = slug ?? {};

    const [contactUsButtonUrlItem] = contactUsButton ?? [];

    return {
      header: {
        cta: {
          action: {
            label: button?.buttonText ?? '',
            url: getHref(url as GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost),
            isExternal: isLinkExternal(
              url as GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost
            ),
          },
          content: {
            desktopTitle: text,
            mobileTitle: mobileText,
          },
        },
        info: {
          mail: email,
          phone: phoneNumber,
        },
        contact: {
          label: 'Contact Us',
          url: getHref(
            contactUsButtonUrlItem as GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost
          ),
          isExternal: isLinkExternal(
            contactUsButtonUrlItem as GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost
          ),
        },
        logo: {
          image: logoImage,
          url: '/',
        },
        menu: getMenuItems(menuItems as GatsbyTypes.SanityMenuLinkOrMenuSubItem[]),
        socials: (socialLinks || []).map((socialLink) => ({
          key: socialLink?._key ?? '',
          url: socialLink?.link?.externalUrl ?? '',
          icon: getImage(socialLink?.icon as GatsbyTypes.SanitySvgImage),
        })),
      },
      footer: {
        info: {
          mail: footerEmail,
          phone: footerPhoneNumber,
        },
        logo: {
          image: footerLogoImage,
          url: '/',
        },
        socials: (footerSocialLinks || []).map((socialLink) => ({
          key: socialLink?._key ?? '',
          url: socialLink?.link?.externalUrl ?? '',
          icon: getImage(socialLink?.icon as GatsbyTypes.SanitySvgImage),
        })),
        menu: {
          items: getFooterMenuItems(items as GatsbyTypes.SanityFooterItem[]),
          label: name,
        },
        copyright,
        location,
      },
      privacyPolicyLink: current,
    };
  }, [sanityCallToActionBar, sanityMainNavigation, sanityFooter, sanityPrivacyPolicy]);
};

export const useIs404Page = (path: string) => {
  const [isClient, setIsClient] = useState(false);

  //  This is an issue with hydration, I'm forcing a rerender on a client so that styled-components can detect a prop change
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
    }
  }, []);

  return isClient && path === '/404.html';
};
