import styled, { css } from 'styled-components';

import { Link } from 'gatsby';
import { InnerLarge } from '../../../common/inner';
import { HeadingS } from '../../styles/Typography';
import media from '../../../common/MediaQueries';
import { CtaBarStylesProps } from './types';
import { transition } from '../../../common/mixins';

export const CtaBar = styled.div`
  height: 3.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.main};

  @media ${media.tablet} {
    height: 4.25rem;
  }
`;

export const CtaBarInner = styled(InnerLarge)`
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${media.tablet} {
    justify-content: space-around;
    padding: 0 0.5rem;
  }
`;

export const CtaBarTypo = styled(HeadingS)<CtaBarStylesProps>`
  text-transform: uppercase;

  ${({ isMobileHidden }) =>
    isMobileHidden &&
    css`
      @media ${media.maxTablet} {
        display: none;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: relative;
      display: block;
      font-size: 0.85rem;
      text-decoration: underline;

      @media ${media.tablet} {
        display: none;
      }
    `}
`;

export const CtaBarContent = styled.div`
  margin-left: 0;
  margin-right: 0;

  @media ${media.tablet} {
    margin-left: 2.5rem;
    margin-right: 1rem;
  }
`;

export const CtaBarAction = styled.div`
  margin-right: 0;

  @media ${media.tablet} {
    margin-right: 2rem;
  }
`;

const linkStyles = css`
  ${transition()};
  display: inline-flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.neutral.white};
  height: 2rem;
  padding: 0.35rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.neutral.white};
  border-radius: 0.5rem;
  text-align: center;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.hover};
    background-color: ${({ theme }) => theme.colors.neutral.white};
  }

  @media ${media.phone} {
    height: 2.25rem;
    padding: 0.35rem 1.5rem;
  }
`;

export const CtaBarActionInternalLink = styled(Link)`
  ${linkStyles}
`;

export const CtaBarActionExternalLink = styled.a`
  ${linkStyles}
`;
