import { scroller } from 'react-scroll';

export const scrollToSection = (target: string, durationVal = 800, delayVal = 0, offsetVal = 0) =>
  scroller.scrollTo(target, {
    duration: durationVal,
    delay: delayVal,
    smooth: true,
    offset: offsetVal,
  });

export const ANIMATION_TRANSLATE_Y_VALUE = '5rem';
