import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { ShouldForwardProp, StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import ThemeWrapper from '../style/ThemeWrapper';

const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }

  return true;
};

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <ThemeWrapper>{element}</ThemeWrapper>
  </StyleSheetManager>
);
