import styled from 'styled-components';
import {
  fontSize,
  color,
  fontWeight,
  fontFamily,
  textAlign,
  letterSpacing,
  lineHeight,
  system,
  TypographyProps,
} from 'styled-system';

const StyledDynamicTypo = styled.p<TypographyProps>`
  ${fontSize}
  ${color}
  ${fontWeight}
  ${fontFamily}
  ${textAlign}
  ${letterSpacing}
  ${lineHeight}
  ${system({
    textTransform: true,
    textDecoration: true,
  })}
`;

export default StyledDynamicTypo;
