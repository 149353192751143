import React from 'react';

import * as Styled from './styles';
import { FooterProps } from './types';
import Image from '../../atoms/Image';
import BaseLink from '../../atoms/Links/BaseLink';
import Socials from '../../molecules/Socials';
import { Typo } from '../../../enums/typo';
import { TextS } from '../../styles/Typography';
import useVisibility from '../../../hooks/useVisibility';
import VisibilitySensorBox from '../VisibilitySensor';

const Footer: React.FC<FooterProps> = ({ data, withBorder }) => {
  const {
    logo: { url, image },
    info,
    socials,
    copyright,
    location,
    menu,
  } = data;
  const [isVisible, onVisibilityChange] = useVisibility();

  return (
    <VisibilitySensorBox {...{ onVisibilityChange }}>
      <Styled.Footer {...{ isVisible }}>
        <Styled.FooterContainer {...{ withBorder }}>
          <Styled.FooterInner>
            <Styled.FooterWrapper>
              {info ? (
                <Styled.FooterInfo>
                  <Styled.FooterLogoWrapper>
                    <Styled.FooterLogo to={url}>
                      <Image {...image} />
                    </Styled.FooterLogo>
                  </Styled.FooterLogoWrapper>
                  <Styled.FooterInfoItem>
                    <BaseLink
                      label={info.mail}
                      url={info.mail}
                      isMail
                      isExternal
                      variant={Typo.TextL}
                      fontWeight="bold"
                    />
                  </Styled.FooterInfoItem>
                  <Styled.FooterInfoItem>
                    <BaseLink
                      label={info.phone}
                      url={info.phone}
                      isPhone
                      isExternal
                      variant={Typo.TextL}
                      fontWeight="bold"
                    />
                  </Styled.FooterInfoItem>
                </Styled.FooterInfo>
              ) : null}
              {menu ? (
                <Styled.FooterMenu>
                  <Styled.FooterMenuLabel fontWeight="900" color="primary.main">
                    {menu.label}
                  </Styled.FooterMenuLabel>
                  <Styled.FooterMenuList>
                    {menu.items.map(
                      ({ key, url: menuItemUrl, label, isExternal, isActivePage }) => (
                        <Styled.FooterMenuListItem {...{ key }}>
                          <BaseLink
                            url={menuItemUrl}
                            {...{ label, isExternal }}
                            fontWeight={isActivePage ? 'bold' : 'regular'}
                          />
                        </Styled.FooterMenuListItem>
                      )
                    )}
                  </Styled.FooterMenuList>
                </Styled.FooterMenu>
              ) : null}
              {socials ? (
                <Styled.FooterSocials>
                  <Styled.FooterSocialsLabel fontWeight="black" color="primary.main">
                    Follow us
                  </Styled.FooterSocialsLabel>
                  <Socials data={socials} />
                </Styled.FooterSocials>
              ) : null}
              {location ? (
                <Styled.FooterLocation>
                  <TextS
                    fontWeight="bold"
                    color="additional.brandDark"
                    dangerouslySetInnerHTML={{ __html: location }}
                  />
                </Styled.FooterLocation>
              ) : null}
              {copyright ? (
                <Styled.FooterCopyright>
                  <TextS fontWeight="bold" color="additional.brandDark">
                    {copyright}
                  </TextS>
                </Styled.FooterCopyright>
              ) : null}
              <Styled.EmptyDiv />
            </Styled.FooterWrapper>
          </Styled.FooterInner>
        </Styled.FooterContainer>
      </Styled.Footer>
    </VisibilitySensorBox>
  );
};

export default Footer;
