import React, { useState, useEffect } from 'react';

import * as Styled from './styles';
import { HeaderProps } from './types';
import Nav from '../Nav';
import Socials from '../../molecules/Socials';
import BaseLink from '../../atoms/Links/BaseLink';
import Burger from '../../molecules/Burger';
import useToggle from '../../../hooks/useToggle';
import useScrollDirection from '../../../hooks/useScrollDirection';
import { Typo } from '../../../enums/typo';
import ScrollDirections from '../../../enums/scroll';
import Image from '../../atoms/Image';
import CtaBar from '../CtaBar';
import { useNavItems } from './hooks';

const Header: React.FC<HeaderProps> = ({ data, showCtaBar, pathname }) => {
  const {
    logo: { url, image },
    menu,
    socials,
    info,
    contact,
    cta,
  } = data;
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [isOpen, { toggle, toggleOff, registerContainerRef }] = useToggle();

  const scrollDirection = useScrollDirection();
  const navItems = useNavItems(pathname, menu);

  useEffect(() => {
    if (cta) {
      if (scrollDirection === ScrollDirections.SCROLL_UP) {
        setIsScrolledDown(false);
      }
      if (scrollDirection === ScrollDirections.SCROLL_DOWN) {
        setIsScrolledDown(true);
      }
    }
  }, [cta, scrollDirection]);

  return (
    <Styled.Header {...{ isScrolledDown }}>
      <Styled.HeaderContent ref={registerContainerRef}>
        <Styled.HeaderInner>
          <Styled.HeaderLogoWrapper onClick={toggleOff}>
            <Styled.HeaderLogo to={url}>
              <Image {...image} />
            </Styled.HeaderLogo>
          </Styled.HeaderLogoWrapper>
          <Styled.HeaderBurger onClick={toggle}>
            <Burger {...{ isOpen }} />
          </Styled.HeaderBurger>
          <Styled.HeaderNav {...{ isOpen }}>
            <Styled.HeaderNavContent>
              <Nav data={navItems} {...{ socials, toggleOff }} />
              {socials ? (
                <Styled.HeaderSocials>
                  <Socials data={socials} />
                </Styled.HeaderSocials>
              ) : null}
              {info ? (
                <Styled.HeaderInfo>
                  <BaseLink
                    label={info.mail}
                    url={info.mail}
                    isMail
                    isExternal
                    variant={Typo.TextL}
                    fontWeight="bold"
                  />
                  <BaseLink
                    label={info.phone}
                    url={info.phone}
                    isPhone
                    isExternal
                    variant={Typo.TextL}
                    fontWeight="bold"
                  />
                </Styled.HeaderInfo>
              ) : null}
            </Styled.HeaderNavContent>
            {contact ? (
              <Styled.HeaderContact onClick={toggleOff}>
                <BaseLink
                  label={contact.label}
                  url={contact.url}
                  isExternal={contact.isExternal}
                  fontWeight="bold"
                  isBtnAccented
                />
              </Styled.HeaderContact>
            ) : null}
          </Styled.HeaderNav>
        </Styled.HeaderInner>
      </Styled.HeaderContent>
      {showCtaBar && cta && (
        <Styled.HeaderBar>
          <CtaBar data={cta} />
        </Styled.HeaderBar>
      )}
    </Styled.Header>
  );
};

export default Header;
