import '../fonts/fonts.css';

export const theme = {
  colors: {
    neutral: {
      white: '#ffffff',
      neutral20: '#F5F5F5',
      neutral30: '#EDEDED',
      neutral40: '#E0E0E0',
      neutral50: '#C2C2C2',
      neutral60: '#9E9E9E',
      neutral70: '#757575',
      neutral80: '#616161',
      neutral90: '#424242',
      black: '#0A0A0A',
    },
    primary: {
      main: '#4AA483',
      surface: '#DBEDE6',
      border: '#C3E1D6',
      hover: '#3E896D',
      pressed: '#255241',
      focus: 'rgba(74, 164, 131, 0.2)',
    },
    danger: {
      main: '#E00000',
      surface: '#FFECEC',
      border: '#FFD7D7',
      hover: '#A20000',
      pressed: '#630000',
    },
    warning: {
      main: '#EA780E',
      surface: '#FFF2E7',
      border: '#FFCA99',
      hover: '#D16603',
      pressed: '#653508',
    },
    success: {
      main: '#008243',
      surface: '#EBF5F0',
      border: '#B3DAC7',
      hover: '#006836',
      pressed: '#005029',
    },
    info: {
      main: '#0B5CD7',
      surface: '#ECF2FC',
      border: '#B6CFF3',
      hover: '#044DBA',
      pressed: '#043C8F',
    },
    additional: {
      brandDark: '#2F405B',
      lightGreen: '#D1F000',
      shadow: '#E2E2E2',
      shadowBlack25: 'rgba(0, 0, 0, 0.25)',
    },
    input: {
      default: '#757575',
      active: '#2F405B',
    },
  },
  breakpoints: ['768px', '1024px', '1200px', '1400px'],
  fontWeights: {
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  fonts: {
    primary: `'Nunito', system-ui, 'sans-serif'`,
    secondary: `'Inter', system-ui, 'sans-serif'`,
  },
  textStyles: {
    textXS: {
      fontSize: ['0.75rem', '0.875rem'],
      lineHeight: '1.3',
      fontFamily: `'Nunito', system-ui, 'sans-serif'`,
    },
    textS: {
      fontSize: '0.75rem',
      lineHeight: '1.3',
      fontFamily: `'Nunito', system-ui, 'sans-serif'`,
    },
    textM: {
      fontSize: '0.875rem',
      lineHeight: '1.4',
      fontFamily: `'Nunito', system-ui, 'sans-serif'`,
    },
    textL: {
      fontSize: '1rem',
      lineHeight: '1.5',
      fontFamily: `'Nunito', system-ui, 'sans-serif'`,
    },
    headingS: {
      fontSize: '1.25rem',
      lineHeight: '1.4',
      fontFamily: `'Inter', system-ui, 'sans-serif'`,
    },
    headingM: {
      fontSize: '1.75rem',
      lineHeight: '1.3',
      fontFamily: `'Inter', system-ui, 'sans-serif'`,
    },
    headingL: {
      fontSize: '2.25rem',
      lineHeight: '1.2',
      fontFamily: `'Inter', system-ui, 'sans-serif'`,
    },
    headingL2: {
      fontSize: ['1.75rem', '2.5rem'],
      lineHeight: '1.4',
      fontFamily: `'Inter', system-ui, 'sans-serif'`,
    },
    headingXL: {
      fontSize: '3.75rem',
      lineHeight: '1.2',
      fontFamily: `'Inter', system-ui, 'sans-serif'`,
    },
  },
  spacings: {
    s: '.25rem',
    m: '0.5rem',
    l: '1rem',
    xl: '1.5rem',
    xxl: '3rem',
  },
};

export type Theme = typeof theme;
