import React from 'react';

import { CtaBarProps } from './types';
import * as Styled from './styles';

import { TextXS } from '../../styles/Typography';

const CtaBar: React.FC<CtaBarProps> = ({ data }) => {
  const {
    action: { label, url, isExternal },
    content: { mobileTitle, desktopTitle },
  } = data;

  return (
    <Styled.CtaBar>
      <Styled.CtaBarInner>
        <Styled.CtaBarContent>
          {desktopTitle ? (
            <Styled.CtaBarTypo
              fontWeight="black"
              color="neutral.white"
              isMobileHidden={!!mobileTitle}
            >
              {desktopTitle}
            </Styled.CtaBarTypo>
          ) : null}
          {mobileTitle ? (
            <Styled.CtaBarTypo fontWeight="black" color="neutral.white" isMobile>
              {mobileTitle}
            </Styled.CtaBarTypo>
          ) : null}
        </Styled.CtaBarContent>
        {label && url && url.length > 0 ? (
          <Styled.CtaBarAction>
            {isExternal ? (
              <Styled.CtaBarActionExternalLink href={url}>
                <TextXS fontWeight="bold" as="p">
                  {label}
                </TextXS>
              </Styled.CtaBarActionExternalLink>
            ) : (
              <Styled.CtaBarActionInternalLink to={url}>
                <TextXS fontWeight="bold" as="p">
                  {label}
                </TextXS>
              </Styled.CtaBarActionInternalLink>
            )}
          </Styled.CtaBarAction>
        ) : null}
      </Styled.CtaBarInner>
    </Styled.CtaBar>
  );
};

export default CtaBar;
