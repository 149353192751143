/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { GatsbyBrowser } from 'gatsby';

export { wrapRootElement } from './src/components/wrapRootElement';
export { wrapPageElement } from './src/components/wrapPageElement';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import('intersection-observer');
  }
};
