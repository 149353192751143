import { theme } from '../style/theme';

const [phone, tablet, desktop] = theme.breakpoints;

const media = {
  phone: `(min-width: ${phone})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
  maxPhone: `(max-width: 767px)`,
  maxTablet: `(max-width: 1023px)`,
};

export default media;
