import React from 'react';
import { GatsbyBrowser } from 'gatsby';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Layout } from '../layouts';
import { GlobalStyle } from '../style/GlobalStyle';
import { NutshellContextProvider } from '../contexts/NutshellerScriptContext';

type WrapPageElementProps = GatsbyBrowser<
  Pick<GatsbyTypes.Query, 'sanityPage' | 'sanityPost' | 'sanityCaseStudy'>
>['wrapPageElement'];

const HeadTags = () => (
  <HelmetProvider>
    <Helmet
      noscript={[
        {
          innerHTML: `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=70896&fmt=gif" />`,
        },
        {
          innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAG_ID}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        },
      ]}
    >
      <script
        src={`https://consent.cookiefirst.com/sites/${process.env.GATSBY_COOKIE_ID}/consent.js`}
      />
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAG_ID}');`}
      </script>
      <script>
        {`(function (w,d,o,a,m) {
          w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments);
          },w[o].e=1*new Date();a=d.createElement('script'),
          m=d.getElementsByTagName('script')[0];a.async=1;
          a.src='https://agent.marketingcloudfx.com/mcfx.js';m.parentNode.insertBefore(a, m);
          })(window, document, 'mcfx');

          mcfx('create', '${process.env.GATSBY_MARKETING_CLOUD_ID}');`}
      </script>
      <script>
        {`(function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </script>
      <script>
        {`_linkedin_partner_id = "${process.env.GATSBY_LINKEDIN_PARTNER_ID}";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
      </script>
    </Helmet>
  </HelmetProvider>
);

export const wrapPageElement: WrapPageElementProps = ({ element, props }) => (
  <NutshellContextProvider>
    <HeadTags />
    <GlobalStyle />
    <Layout {...props}>{element}</Layout>
  </NutshellContextProvider>
);
