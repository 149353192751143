import styled, { css } from 'styled-components';

import { BurgerProps } from './types';
import { transition } from '../../../common/mixins';

export const BurgerIcon = styled.span`
  ${transition()}
  display: inline-flex;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.8125rem;
  width: 0.8125rem;
  transform: translate(-50%, -50%);
  &:last-child {
    opacity: 0;
  }
`;

export const Burger = styled.div<BurgerProps>`
  ${transition()}
  position: relative;
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.primary.surface};

  ${({ isOpen }) =>
    isOpen &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.main};
      box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colors.additional.shadowBlack25};
      ${BurgerIcon} {
        opacity: 0;
      }
      ${BurgerIcon}:last-child {
        opacity: 1;
      }
    `}
`;
