import React from 'react';

import * as Styled from '../styles';
import { LinkProps } from '../types';
import { renderTypoSwitch } from '../const';

const BaseLink: React.FC<LinkProps> = ({
  url,
  label,
  isExternal,
  variant,
  color,
  backgroundColor,
  fontWeight,
  isMail,
  isPhone,
  isBtnAccented,
  isBtnBordered,
  isTransparent,
  isCMS,
  childrenWrapperElement = 'p',
}) => {
  let colorVal;

  if (color) {
    colorVal = color;
  } else if (isBtnAccented) {
    colorVal = `neutral.white`;
  } else if (isBtnBordered) {
    colorVal = `additional.lightGreen`;
  } else {
    colorVal = 'additional.brandDark';
  }

  // eslint-disable-next-line no-nested-ternary
  const hrefVal = isMail ? `mailto:${url}` : isPhone ? `tel:${url}` : url;

  return (
    <>
      {isExternal ? (
        <Styled.LinkExternal
          {...{ isBtnAccented, isBtnBordered, isTransparent, backgroundColor, isCMS }}
          as="a"
          href={`${hrefVal}`}
          target={isMail || isPhone ? undefined : '_blank'}
          rel={isMail || isPhone ? undefined : 'noreferrer noopener'}
        >
          {renderTypoSwitch(label, variant, colorVal, fontWeight, childrenWrapperElement)}
        </Styled.LinkExternal>
      ) : (
        <Styled.LinkInternal
          {...{ isBtnAccented, isBtnBordered, isTransparent, backgroundColor, isCMS }}
          to={hrefVal || ''}
        >
          {renderTypoSwitch(label, variant, colorVal, fontWeight, childrenWrapperElement)}
        </Styled.LinkInternal>
      )}
    </>
  );
};

export default BaseLink;
