import React from 'react';

import { BurgerProps } from './types';
import * as Styled from './styles';
import { IconMenuDots, IconClose } from '../../../assets/svg';

const Burger: React.FC<BurgerProps> = ({ isOpen }) => (
  <Styled.Burger {...{ isOpen }}>
    <Styled.BurgerIcon>
      <IconMenuDots />
    </Styled.BurgerIcon>
    <Styled.BurgerIcon>
      <IconClose />
    </Styled.BurgerIcon>
  </Styled.Burger>
);

export default Burger;
