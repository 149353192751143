import React, { useState } from 'react';

import * as Styled from './styles';
import { NavProps, NavItemProps } from './types';
import BaseLink from '../../atoms/Links/BaseLink';
import { HeadingS } from '../../styles/Typography';
import Socials from '../../molecules/Socials';
import { Typo } from '../../../enums/typo';

const Nav: React.FC<NavProps> = ({ data, socials, toggleOff }) => {
  const NavAccordion: React.FC<NavItemProps> = ({
    label,
    url,
    isExternal,
    isActivePage,
    items,
    index,
  }) => {
    const [isOpen, setIsOpen] = useState(true);

    return (
      <Styled.NavListItem {...{ isOpen }}>
        <Styled.NavStaticLabel hasSubmenu={!!(items && items.length)} {...{ isActivePage }}>
          <Styled.NavListLinkWrapper onClick={toggleOff}>
            <BaseLink {...{ url, label, isExternal }} fontWeight={['black', '', 'regular']} />
          </Styled.NavListLinkWrapper>

          {items && items.length ? (
            <Styled.NavAccordionBtn onClick={() => setIsOpen(!isOpen)} />
          ) : null}
        </Styled.NavStaticLabel>

        {items && items.length ? (
          <Styled.NavMenu isLeftAligned={!!index && index > 2}>
            <Styled.NavMenuContent>
              <Styled.NavListLinkWrapper onClick={toggleOff}>
                <BaseLink
                  {...{ url, label, isExternal }}
                  fontWeight="black"
                  color="primary.main"
                  variant={Typo.HeadingS}
                />
              </Styled.NavListLinkWrapper>

              <Styled.NavMenuList>
                {items.map(({ key: navKey, itemLabel, itemUrl, itemIsExternal, itemIsActive }) => (
                  <Styled.NavMenuListItem key={navKey} itemIsActive={itemIsActive}>
                    <Styled.NavListLinkWrapper onClick={toggleOff}>
                      <BaseLink label={itemLabel} url={itemUrl} isExternal={itemIsExternal} />
                    </Styled.NavListLinkWrapper>
                  </Styled.NavMenuListItem>
                ))}
              </Styled.NavMenuList>

              {socials ? (
                <Styled.NavMenuSocials>
                  <Styled.NavMenuSocialsLabel>
                    <HeadingS fontWeight="black" color="primary.main">
                      Follow us
                    </HeadingS>
                  </Styled.NavMenuSocialsLabel>

                  <Styled.NavMenuSocialsList>
                    <Socials data={socials} />
                  </Styled.NavMenuSocialsList>
                </Styled.NavMenuSocials>
              ) : null}
            </Styled.NavMenuContent>
          </Styled.NavMenu>
        ) : null}
      </Styled.NavListItem>
    );
  };

  return (
    <Styled.NavList>
      {data.map(({ key, label, url, isExternal, isActivePage, items }, index) => (
        <NavAccordion {...{ key, label, url, isExternal, isActivePage, items, index }} />
      ))}
    </Styled.NavList>
  );
};

export default Nav;
