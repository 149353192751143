import styled, { css } from 'styled-components';

import media from '../../../common/MediaQueries';
import { transition, transitionDelay } from '../../../common/mixins';
import { LinkInternal, LinkExternal } from '../../atoms/Links/styles';
import {
  NavMenuProps,
  NavAccordionBtnProps,
  NavStaticLabelProps,
  NavMenuSocialsProps,
  NavListLinkProps,
} from './types';

export const NavStaticLabel = styled.div<NavStaticLabelProps>`
  ${transition()}
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  &::after {
    ${transition()}
    position: absolute;
    display: block;
    content: '';
    background-color: ${({ theme }) => theme.colors.additional.brandDark};
    height: 0.1875rem;
    width: 10rem;
    bottom: 0;
    left: 0;
  }

  ${LinkExternal}, ${LinkInternal} {
    padding: 0.625rem 0;
  }

  @media ${media.maxTablet} {
    align-items: center;
    p {
      font-size: 1.25rem;
      font-family: ${({ theme }) => theme.fonts.secondary};
      color: ${({ theme }) => theme.colors.additional.brandDark};
    }
  }

  @media ${media.tablet} {
    cursor: pointer;
    p {
      color: ${({ theme }) => theme.colors.neutral.neutral80};
    }

    ${LinkExternal}, ${LinkInternal} {
      padding: 0.875rem 1rem;
    }

    &::after {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.primary.main};
      transform: translateY(100%);
      opacity: 0;
    }
  }

  ${({ hasSubmenu }) =>
    hasSubmenu &&
    css`
      @media ${media.tablet} {
        cursor: pointer;
      }
    `}

  ${({ isActivePage }) =>
    isActivePage &&
    css`
      @media ${media.tablet} {
        p {
          color: ${({ theme }) => theme.colors.additional.brandDark};
          text-shadow: 0px 0px 0.6px ${({ theme }) => theme.colors.additional.brandDark},
            0px 0px 0 ${({ theme }) => theme.colors.additional.brandDark},
            0px 0px 0.6px ${({ theme }) => theme.colors.additional.brandDark},
            0px 0px 0 ${({ theme }) => theme.colors.additional.brandDark};
        }
        &::after {
          transform: translateY(0%);
          opacity: 1;
        }
      }
    `}
`;

export const NavAccordionBtn = styled.button<NavAccordionBtnProps>`
  position: relative;
  width: 1.3125rem;
  height: 1.3125rem;
  &::after,
  &::before {
    position: absolute;
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.colors.additional.brandDark};
  }

  &::before {
    width: 100%;
    height: 0.125rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &::after {
    ${transition()}
    width: 0.125rem;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media ${media.tablet} {
    display: none;
  }
`;

export const NavMenu = styled.div<NavMenuProps>`
  ${transition()}
  z-index: 20;
  max-height: 0;
  overflow: hidden;
  opacity: 0;

  @media ${media.maxTablet} {
    width: 100%;
  }

  @media ${media.tablet} {
    position: absolute;
    width: 27.5rem;
    left: -2.125rem;
    top: 2.9375rem;

    ${({ isLeftAligned }) =>
      isLeftAligned &&
      css`
        left: auto;
        right: -0.25rem;
      `}
  }
`;

export const NavListLinkWrapper = styled.div``;

export const NavListItem = styled.li<NavMenuProps>`
  transition: all 270ms ease;
  ${transitionDelay()}
  position: relative;

  &:not(:first-child) {
    margin-top: 0.375rem;
  }
  @media ${media.maxTablet} {
    ${({ isOpen }) =>
      isOpen &&
      css`
        > ${NavMenu} {
          opacity: 1;
          max-height: 1000px;
        }
        ${NavAccordionBtn} {
          &::after {
            transform: rotate(-90deg);
          }
        }
      `}
  }

  @media ${media.maxTablet} {
    opacity: 0;
    transform: translateY(100%);
    overflow: hidden;
  }

  @media ${media.tablet} {
    &:not(:first-child) {
      margin-top: 0;
      margin-left: 0.5rem;
    }
    &:hover {
      ${NavStaticLabel} p {
        color: ${({ theme }) => theme.colors.additional.brandDark};
        text-shadow: 0px 0px 0.6px ${({ theme }) => theme.colors.additional.brandDark},
          0px 0px 0 ${({ theme }) => theme.colors.additional.brandDark},
          0px 0px 0.6px ${({ theme }) => theme.colors.additional.brandDark},
          0px 0px 0 ${({ theme }) => theme.colors.additional.brandDark};
      }
      ${NavStaticLabel} {
        &::after {
          transform: translateY(0%);
          opacity: 1;
        }
      }
      ${NavMenu} {
        opacity: 1;
        max-height: 1000px;
        overflow: hidden;
        padding-top: 1rem;
      }
    }
  }
`;

export const NavMenuContent = styled.div`
  @media ${media.maxTablet} {
    margin-top: 1rem;
    > div {
      display: none;
    }
  }
  @media ${media.tablet} {
    padding: 2.5rem 2.5rem 3rem;
    border: 0.125rem solid ${({ theme }) => theme.colors.primary.main};
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.neutral.white};
  }
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: row;
  @media ${media.maxTablet} {
    flex-direction: column;
    width: 100%;
  }
`;

export const NavMenuList = styled.ul`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1.5rem);
    margin-left: -0.75rem;
  }
`;

export const NavMenuListItem = styled.li<NavListLinkProps>`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  p {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  ${({ itemIsActive }) =>
    itemIsActive &&
    css`
      p {
        color: ${({ theme }) => theme.colors.additional.brandDark} !important;
      }
    `}
  @media ${media.tablet} {
    width: 50%;
    padding: 1.5rem 0.75rem 0;
    p {
      color: ${({ theme }) => theme.colors.additional.brandDark};
    }
    ${({ itemIsActive }) =>
      itemIsActive &&
      css`
        p {
          color: ${({ theme }) => theme.colors.primary.main} !important;
        }
      `}
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;

export const NavMenuSocials = styled.div<NavMenuSocialsProps>`
  display: none;
  @media ${media.tablet} {
    display: block;
  }
`;

export const NavMenuSocialsLabel = styled.div`
  padding: 2.5rem 0 1.5rem;
`;

export const NavMenuSocialsList = styled.div``;
