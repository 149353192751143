import styled, { css } from 'styled-components';

const innerStyles = css`
  width: 100%;
  margin: 0 auto;
`;

export const InnerLarge = styled.div`
  ${innerStyles}
  max-width: 81rem;
  padding: 0 0.5rem;
`;

export const Inner = styled.div`
  ${innerStyles}
  max-width: 71rem;
  padding: 0 1.5rem;
`;

export const InnerSmall = styled.div`
  ${innerStyles}
  max-width: 63.25rem;
  padding: 0 1.5rem;
`;

export const InnerExtraSmall = styled.div`
  ${innerStyles}
  max-width: 55.5rem;
  padding: 0 1.5rem;
`;

export const InnerExtraSmallSecondary = styled.div`
  ${innerStyles}
  max-width: 58.5rem;
  padding: 0 1.5rem;
`;
