import styled from 'styled-components';

import { transition } from '../../../common/mixins';
import media from '../../../common/MediaQueries';

export const SocialsList = styled.ul`
  ${transition()}
  display: flex;
`;

export const SocialsListItem = styled.li`
  &:not(:first-child) {
    margin-left: 1.5rem;
  }
`;

export const SocialListLink = styled.a`
  ${transition()}
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.additional.brandDark};

  svg {
    ${transition()}
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
    svg {
      opacity: 0.8;
    }
  }

  @media ${media.tablet} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
