import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';

interface NutshellerContextValue {
  loadScript: () => void;
  loading: boolean;
}

const NutshellerContext = React.createContext<NutshellerContextValue>({
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadScript: () => {},
});

const nutshellScriptUrl = 'https://loader.nutshell.com/nutsheller.js';

export const NutshellContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    /* eslint-disable */
    (function (n, u, t) {
      // @ts-expect-error
      n[u] =
        // @ts-expect-error
        n[u] ||
        function () {
          // @ts-expect-error
          (n[u].q = n[u].q || []).push(arguments);
        };
    })(window, 'Nutsheller');
    /* eslint-enable */
  }, []);

  const [loading, setLoading] = useState(true);

  const loadScript = () => {
    const existingScript = document.querySelector(
      'script[src="https://loader.nutshell.com/nutsheller.js"'
    );

    if (existingScript) {
      setLoading(false);
      return;
    }

    const scriptTag = document.createElement('script');

    scriptTag.src = nutshellScriptUrl;
    scriptTag.type = 'text/javascript';
    scriptTag.defer = true;

    scriptTag.onload = () => {
      setLoading(false);
    };

    document.head.appendChild(scriptTag);
  };

  return (
    <NutshellerContext.Provider value={{ loading, loadScript }}>
      {children}
    </NutshellerContext.Provider>
  );
};

export const useNutshellerContext = () => useContext(NutshellerContext);
