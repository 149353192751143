import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { HeaderStyledProps, HeaderNavStyledProps } from './types';
import { InnerLarge } from '../../../common/inner';
import media from '../../../common/MediaQueries';
import { transition } from '../../../common/mixins';
import { NavListItem } from '../Nav/styles';

export const HeaderBar = styled.div`
  ${transition()}
  transform: translateY(0);
  z-index: 0;
`;

export const HeaderContent = styled.div`
  ${transition()}
  background-color: ${({ theme }) => theme.colors.neutral.white};
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding: 1rem 0;
  z-index: 1;
  @media ${media.phone} {
    min-height: 5rem;
    padding: 0.9375rem 0;
  }
`;

export const Header = styled.header<HeaderStyledProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;

  ${({ isScrolledDown }) =>
    isScrolledDown &&
    css`
      ${HeaderBar} {
        transform: translateY(-4.25rem);
        @media ${media.tablet} {
          transform: translateY(-4.25rem);
        }
      }
      ${HeaderContent} {
        @media ${media.tablet} {
          transform: translateY(-4.5rem);
        }
      }
    `}

  @media ${media.tablet} {
    flex-direction: column-reverse;
  }
`;

export const HeaderInner = styled(InnerLarge)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${media.maxTablet} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const HeaderLogoWrapper = styled.div``;

export const HeaderLogo = styled(Link)`
  ${transition()}
  display: block;
  height: 2rem;
  width: 7.5rem;
  @media ${media.tablet} {
    margin-left: 5rem;
    width: 11.5rem;
    height: 3.125rem;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const HeaderLogoImg = styled(GatsbyImage)``;

export const HeaderNav = styled.div<HeaderNavStyledProps>`
  ${transition()}
  transition-duration: 280ms;
  z-index: 20;

  @media ${media.maxTablet} {
    background-color: ${({ theme }) => theme.colors.neutral.white};
    position: fixed;
    top: 5rem;
    left: 0;
    width: 100vw;
    height: calc(100vh - 1rem);
    overflow: hidden;
    padding: 0 0.5rem;
    opacity: 0;
    max-height: 0;

    ${({ isOpen }) =>
      isOpen &&
      css`
        opacity: 1;
        max-height: 125rem;
        padding: 0 0.5rem 4rem;
        ${NavListItem} {
          opacity: 1;
          transform: translateY(0);
        }
      `}
  }

  @media ${media.maxPhone} {
    top: 4rem;
  }
`;

export const HeaderNavContent = styled.div`
  @media ${media.maxTablet} {
    border: 0.125rem solid ${({ theme }) => theme.colors.primary.main};
    border-bottom: 0;
    background-color: ${({ theme }) => theme.colors.neutral.white};
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: calc(100% - 4rem);
    padding: 1.5rem 1.25rem 0 1.5rem;
    overflow: auto;
  }
`;

export const HeaderSocials = styled.div`
  margin-top: 2.5rem;
  @media ${media.tablet} {
    display: none;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;

  margin: 2rem 0 1.5rem;
  > * + * {
    margin-top: 0.5rem;
  }
  @media ${media.tablet} {
    display: none;
  }
`;

export const HeaderContact = styled.div`
  a {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 4.25rem;
    align-items: center;
  }
  @media ${media.tablet} {
    display: none;
  }
`;

export const HeaderBurger = styled.button`
  @media ${media.tablet} {
    display: none;
  }
`;
