import { useCallback, useState } from 'react';
import { useIsAnimationEnabled } from '../contexts/AnimationContext/hooks';

const useVisibility = (): [boolean, (visibility: boolean) => void] => {
  const animationEnabled = useIsAnimationEnabled();
  const [isVisible, setIsVisible] = useState(!animationEnabled);

  const onVisibilityChange = useCallback((visibility: boolean): void => {
    if (visibility) {
      setIsVisible(true);
    }
  }, []);

  return [isVisible, onVisibilityChange];
};

export default useVisibility;
