import React, { PropsWithChildren } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { VisibilitySensorBoxProps } from './types';

const VisibilitySensorBox: React.FC<PropsWithChildren<VisibilitySensorBoxProps>> = ({
  children,
  onVisibilityChange,
}) => {
  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      delayedCall
      resizeCheck
      partialVisibility
      offset={{ top: 50 }}
    >
      {children}
    </VisibilitySensor>
  );
};

export default VisibilitySensorBox;
