import isOfType from './isOfType';

export const isOfLinkType = <T extends GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost>(
  link: GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost | undefined | null,
  linkTypeName: string
): link is T =>
  isOfType<GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost>(link, linkTypeName);

export const isLinkExternal = (
  link: GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost | null
): boolean =>
  !!link &&
  (isOfLinkType<GatsbyTypes.SanityExternalUrl>(link, 'externalUrl') ||
    isOfLinkType<GatsbyTypes.SanityMedia>(link, 'media'));

export const getHref = (
  link: GatsbyTypes.SanityCaseStudyOrExternalUrlOrMediaOrPageOrPost | null | undefined
): string => {
  if (!link) {
    return '';
  }

  if (isOfLinkType<GatsbyTypes.SanityExternalUrl>(link, 'externalUrl')) {
    return link.externalUrl ?? '';
  }

  if (isOfLinkType<GatsbyTypes.SanityMedia>(link, 'media')) {
    return link.asset?.url ?? '';
  }

  const slug = (link as GatsbyTypes.SanityPage).slug?.current;
  return slug ?? '';
};

export const isMail = (url: string): boolean => url.startsWith('mailto');
export const isPhone = (url: string): boolean => url.startsWith('tel');
