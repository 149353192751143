import React, { useEffect } from 'react';

import * as Styled from './styles';
import { BackgroundPatternImage } from '../../../assets/svg';

const BackgroundPattern = () => {
  useEffect(() => {
    const mainEl = document.getElementsByTagName('main');
    mainEl[0].classList.add('is-hidden');
  });

  return (
    <Styled.BackgroundPatternWrapper>
      <BackgroundPatternImage />
    </Styled.BackgroundPatternWrapper>
  );
};

export default BackgroundPattern;
