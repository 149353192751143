import React, { FunctionComponent } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { SEOProps } from './types';

export const SEO: FunctionComponent<SEOProps> = ({ location, pageMetadata }) => {
  const {
    title,
    description,
    keywords,
    siteUrl = location.host,
    imageUrl,
    language,
  } = useSiteMetadata();

  return (
    <HelmetProvider>
      <Helmet title={pageMetadata?.title || ''} defaultTitle={title} titleTemplate="%s">
        <html lang={pageMetadata?.language || language} />
        <meta name="description" content={pageMetadata?.description || description} />
        <meta name="keywords" content={(pageMetadata?.keywords || keywords)?.join(', ')} />
        <meta property="og:title" content={pageMetadata?.title || title} />
        <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        <meta property="og:description" content={pageMetadata?.description || description} />
        <meta property="og:type" content={pageMetadata?.type || 'website'} />
        {(imageUrl || pageMetadata?.imageUrl) && (
          <meta property="og:image" content={pageMetadata?.imageUrl || `${siteUrl}${imageUrl}`} />
        )}
        <meta property="og:image:alt" content={pageMetadata?.title || title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
    </HelmetProvider>
  );
};
