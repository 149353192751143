import { GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { ImageNode } from 'gatsby-source-sanity/lib-es5/images/getGatsbyImageProps';
import { LinkProps } from '../components/atoms/Links/types';
import { getHref, isLinkExternal } from './link';

export const getImage = (
  image: GatsbyTypes.Maybe<GatsbyTypes.SanityImageWithAlt | GatsbyTypes.SanitySvgImage> | undefined
): GatsbyImageProps => ({
  alt: image?.alt ?? '',
  image: image?.asset?.gatsbyImageData as IGatsbyImageData,
});

const sanityLocation = {
  dataset: process.env.SANITY_STUDIO_DATASET as string,
  projectId: process.env.SANITY_STUDIO_PROJECT_ID as string,
};

export const mapSanityImageToGatsbyImage = (
  image: GatsbyTypes.Maybe<GatsbyTypes.SanityImageWithAlt> | undefined
): GatsbyImageProps & { link: LinkProps } => {
  const { alt = '', link } = image ?? {};
  const [urlItem] = link ?? [];

  const linkProps: LinkProps = {
    url: getHref(urlItem),
    isExternal: isLinkExternal(urlItem),
    label: '',
  };

  return {
    alt: alt || '',
    image: getGatsbyImageData(
      image as ImageNode,
      {
        placeholder: 'blurred',
      },
      sanityLocation
    ) as IGatsbyImageData,
    link: linkProps,
  };
};
