import { FooterMenuItemsProps } from '../components/organisms/Footer/types';
import { NavItemProps } from '../components/organisms/Nav/types';
import isOfType from './isOfType';
import { getHref, isLinkExternal } from './link';

export const isOfMenuItemType = <T extends GatsbyTypes.SanityMenuLinkOrMenuSubItem>(
  menuItem: GatsbyTypes.SanityMenuLinkOrMenuSubItem | undefined,
  menuItemTypeName: string
): menuItem is T => isOfType<GatsbyTypes.SanityMenuLinkOrMenuSubItem>(menuItem, menuItemTypeName);

export const getMenuItems = (
  menuItems: GatsbyTypes.SanityMenuLinkOrMenuSubItem[]
): NavItemProps[] =>
  menuItems.map<NavItemProps>((menuItem) => {
    if (isOfMenuItemType<GatsbyTypes.SanityMenuSubItem>(menuItem, 'menuSubItem')) {
      const { _key: key = '', link: topLink, label = '', menuLinks = [] } = menuItem ?? {};
      const [topUrl] = topLink ?? [];

      return {
        key,
        label,
        url: getHref(topUrl),
        isExternal: isLinkExternal(topUrl),
        items: menuLinks?.map((menuLink) => {
          const { _key: menuLinkKey = '', label: itemLabel = '', link } = menuLink ?? {};
          const [url] = link ?? [];

          return {
            key: menuLinkKey,
            itemLabel,
            itemUrl: getHref(url),
            itemIsExternal: isLinkExternal(url),
          };
        }),
      };
    }

    const { _key: key = '', label = '', link } = menuItem ?? {};
    const [url] = link ?? [];

    return {
      key,
      label,
      url: getHref(url),
      isExternal: isLinkExternal(url),
    };
  });

export const getFooterMenuItems = (
  footerMenuItems: GatsbyTypes.SanityFooterItem[]
): FooterMenuItemsProps[] =>
  footerMenuItems.map<FooterMenuItemsProps>((footerMenuItem) => {
    const { _key: key = '', label = '', link } = footerMenuItem;
    const [url] = link ?? [];

    return {
      key,
      label,
      url: getHref(url),
      isExternal: isLinkExternal(url),
    };
  });
