import React from 'react';
import { CookiesProvider } from 'react-cookie';
import * as Styled from './styles';
import { SEO } from '../components/SEO';
import Header from '../components/organisms/Header';
import Footer from '../components/organisms/Footer';
import { useGlobalSettings, useIs404Page } from './hooks';
import { LayoutProps } from './types';
import BackgroundPattern from '../components/atoms/BackgroundPattern';

export const Layout: LayoutProps = ({ children, location, data, pageResources }) => {
  const { header, footer } = useGlobalSettings();
  const { sanityPage, sanityPost, sanityCaseStudy } = data ?? {};

  const { pathname } = location;
  const { page } = pageResources ?? {};
  const { path } = page ?? {};

  const is404Page = useIs404Page(path);

  const showCtaBar = !!(
    sanityPage?.showCallToActionBar ||
    sanityPost?.showCallToActionBar ||
    sanityCaseStudy?.showCallToActionBar
  );

  const showBorderedFooter = !!(
    sanityPage?.showBorderedFooter ||
    sanityPost?.showBorderedFooter ||
    sanityCaseStudy?.showBorderedFooter
  );

  const showBackgroundPattern = !!(
    sanityPage?.showBackgroundPattern ||
    sanityPost?.showBackgroundPattern ||
    sanityCaseStudy?.showBackgroundPattern
  );

  return (
    <CookiesProvider>
      <SEO {...{ location }} />
      <Header data={header} {...{ showCtaBar, pathname }} />
      <Styled.Main {...{ showCtaBar }}>
        {showBackgroundPattern && <BackgroundPattern />}
        {children}
      </Styled.Main>
      <Footer data={footer} withBorder={showBorderedFooter || is404Page} />
    </CookiesProvider>
  );
};
