import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { NavItemProps } from '../Nav/types';

export const useResourcesUrl = () => {
  const { sanityContentLists } = useStaticQuery<GatsbyTypes.GetResourcesUrlQuery>(graphql`
    query GetResourcesUrl {
      sanityContentLists {
        resources {
          slug {
            current
          }
        }
      }
    }
  `);

  const { resources } = sanityContentLists ?? {};
  const { slug } = resources ?? {};
  const { current } = slug ?? {};

  return current;
};

export const useNavItems = (path: string, navItems: NavItemProps[]) => {
  const [_navItems, setNavItems] = useState(navItems);
  const resourcesUrl = useResourcesUrl();

  useEffect(() => {
    const newNavItems = navItems.map((item) => {
      const { url, items = [] } = item;
      const activeSubItem = (items || []).find(
        ({ itemUrl }) => `${itemUrl}/` === path || itemUrl === path
      );

      if (`${url}/` === resourcesUrl || url === resourcesUrl) {
        const isBlogPost =
          (`${url}/` === resourcesUrl || url === resourcesUrl) && path.startsWith('/blog/');
        const isCaseStudy =
          (`${url}/` === resourcesUrl || url === resourcesUrl) && path.startsWith('/customers/');

        return {
          ...item,
          isActivePage:
            `${url}/` === path || url === path || isBlogPost || isCaseStudy || !!activeSubItem,
          items: (items || []).map((subItem) => {
            const isSubItemActive = subItem.itemUrl === path;

            if (subItem.itemUrl === '/blog') {
              return {
                ...subItem,
                itemIsActive: isSubItemActive || isBlogPost,
              };
            }

            if (subItem.itemUrl === '/customers') {
              return {
                ...subItem,
                itemIsActive: isSubItemActive || isCaseStudy,
              };
            }

            return subItem;
          }),
        };
      }

      return {
        ...item,
        isActivePage: `${url}/` === path || url === path || !!activeSubItem,
        items: (items || []).map((subItem) => ({
          ...subItem,
          itemIsActive: !!activeSubItem && activeSubItem.itemUrl === subItem.itemUrl,
        })),
      };
    });

    setNavItems(newNavItems);
  }, [navItems, resourcesUrl, path]);

  return _navItems;
};
