import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { ImageStyled } from './styles';
import { ImageProps } from './types';

const Image: React.FC<ImageProps> = ({ image, objectFit, objectPosition, alt, className }) => {
  const imageFluid = getImage(image);

  return (
    <ImageStyled {...{ className }}>
      {imageFluid && (
        <GatsbyImage
          image={imageFluid}
          objectFit={objectFit || 'contain'}
          objectPosition={objectPosition || '50% 50%'}
          style={{ height: '100%', width: '100%' }}
          loading="lazy"
          {...{ alt }}
        />
      )}
    </ImageStyled>
  );
};

export default Image;
