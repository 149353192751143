import React from 'react';

import { ReactSVG } from 'react-svg';
import * as Styled from './styles';
import { SocialsProps } from './types';

const Socials: React.FC<SocialsProps> = ({ data }) => (
  <Styled.SocialsList>
    {data.map(({ key, url, icon }) => (
      <Styled.SocialsListItem {...{ key }}>
        <Styled.SocialListLink href={url} target="_blank" rel="noreferrer noopener">
          {icon?.image?.images?.fallback?.src &&
            icon.image.images.fallback.src.includes('.svg') && (
              <ReactSVG src={icon.image.images.fallback?.src} />
            )}
        </Styled.SocialListLink>
      </Styled.SocialsListItem>
    ))}
  </Styled.SocialsList>
);

export default Socials;
