import styled, { css } from 'styled-components';

import media from '../common/MediaQueries';

export const Main = styled.main<{ showCtaBar: boolean }>`
  margin-top: 4rem;
  position: relative;

  @media ${media.phone} {
    margin-top: 5rem;
  }

  &.is-hidden {
    overflow: hidden;
  }

  ${({ showCtaBar }) =>
    showCtaBar &&
    css`
      margin-top: 7.75rem;
      @media ${media.phone} {
        margin-top: 8.75rem;
      }
      @media ${media.tablet} {
        margin-top: 9.25rem;
      }
    `}
`;
