import { css } from 'styled-components';

import media from './MediaQueries';
import { ANIMATION_TRANSLATE_Y_VALUE } from './helpers';

export const transition = (duration = '200ms') => css`
  transition: all ${duration} ease-in-out;
`;

export const opacityTransformTransition = (duration = '0.4s', delay = '0.25s') => css`
  transition: opacity ${duration} ease ${delay}, transform ${duration} ease ${delay};
`;

export const customOpacityTransformTransition = css`
  transition: transform 0.6s ease-out, opacity 1s ease 0.4s;
`;

export const easeTransition = (duration = '0.4s', delay = '0.25s') => css`
  transition: ${duration} ease ${delay};
`;

export const slideInStartAnimStyles = () =>
  css`
    transform: translateY(${ANIMATION_TRANSLATE_Y_VALUE});
    opacity: 0;
  `;

export const slideInEndAnimStyles = () =>
  css`
    transform: translateY(0);
    opacity: 1;
  `;

export const transitionDelay = (initVal = 120) => {
  let styles = '';

  for (let i = 0; i < 90; i += 1) {
    styles += `
    &:nth-child(${i}) {
      transition-delay: ${i * 150 + initVal}ms;
    }
     `;
  }

  return css`
    ${styles}
  `;
};

export const lineDecor = () => css`
  position: relative;
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 8.625rem;
    height: 0.875rem;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.primary.main};
    border-radius: 0.25rem;
  }

  @media ${media.phone} {
    &::after {
      width: 15rem;
    }
  }
`;
