import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import { ThemeWrapperProps } from './types';

const ThemeWrapper: React.FC<PropsWithChildren<ThemeWrapperProps>> = ({
  theme: newTheme,
  children,
}) => {
  return <ThemeProvider theme={newTheme || theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
